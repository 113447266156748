import './shared/abstract-control.extension';

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';

import { LoaderComponent } from './shared/components/loader/loader.component';
import { UploadProgressLoaderComponent } from './shared/components/upload-progress-loader/upload-progress-loader.component';
import { SocketService } from './shared/services/socket.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    LoaderComponent,
    UploadProgressLoaderComponent,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'griot-admin';
  contentType!: string;

  constructor(
    private socketService: SocketService,
    private route: ActivatedRoute
  ) {
    this.socketService.connect();
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe((res) => {
      this.contentType = res['type'];
    });
  }
}
