import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { delay,Observable } from 'rxjs';

import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  standalone: true,
  imports: [CommonModule],
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  // subscriber for checking flag for show/hide loader
  isLoading: Observable<boolean> = this.loaderService.isLoading.pipe(delay(0));

  constructor(private loaderService: LoaderService) {}
}
