import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { map, Observable, tap } from 'rxjs';
import { User } from 'src/app/pages/user/models/users.model';

import { ApiResponse } from '../models/api-response.model';
import { Login } from '../models/login.model';
import { LocalStorageService } from './local-storage.service';
import { Roles } from '../enums/role.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // access token key to get the user details from local storage
  private readonly accessTokenKey = 'access_token';

  // refresh token key to get the user details from local storage
  private readonly accessToRefreshKey = 'refresh_token';

  // log in credential key to get the login details from local storage
  private readonly credentialStorageKey = 'credential_storage';

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject('API_URL') private apiUrl: string,
    private localStorageService: LocalStorageService
  ) {}

  /**
   * Login the end user.
   * @param email - email id to login
   * @param password - password to login
   * @returns
   */
  login(
    email: string,
    password: string,
    rememberMe: boolean
  ): Observable<ApiResponse<Login>> {
    return this.http
      .post<ApiResponse<Login>>(`${this.apiUrl}/auth/signIn`, {
        email,
        password,
      })
      .pipe(
        tap((response) => {
          const accessToken = response.data?.accessToken;
          const refreshToken = response.data?.refreshToken;
          const decodedToken = jwtDecode<User>(accessToken);
          if (
            decodedToken.Role.name === Roles.ADMIN ||
            decodedToken.Role.name === Roles.SUPER_ADMIN
          ) {
            this.setAccessToken(accessToken);
            this.setRefreshToken(refreshToken);
            if (rememberMe) {
              this.saveCredentials(email, rememberMe);
            } else {
              this.localStorageService.removeItem(this.credentialStorageKey);
            }
          } else {
            throw new Error(
              'Access Denied: You do not have permission to login'
            );
          }
        })
      );
  }

  /**
   * Get new access token based on the refresh token.
   * @param refreshToken - refresh token
   * @returns
   */
  getNewAccessToken(refreshToken: string): Observable<string> {
    return this.http
      .get<ApiResponse<Login>>(
        `${this.apiUrl}/auth/refreshToken/${refreshToken}`
      )
      .pipe(
        map((response) => {
          const newAccessToken = response.data?.accessToken;
          this.setAccessToken(newAccessToken);
          return newAccessToken;
        })
      );
  }

  /**
   * Send mail to reset password.
   * @param email - email on which the link will be sent to reset the password.
   * @returns
   */
  sendMailToResetPassword(email: string): Observable<void> {
    return this.http.get<void>(
      `${this.apiUrl}/auth/forgotPassword/${email}/admin`
    );
  }

  /**
   * To change/reset the password.
   * @param email - email for which the password need to be reset.
   * @param token - token for verification.
   * @returns
   */
  resetPassword(password: string, token: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/auth/reSetPassword`, {
      password,
      token,
    });
  }

  /**
   * set access token
   * @param token - the access token to set in local storage
   */
  private setAccessToken(token: string): void {
    this.localStorageService.setItem(this.accessTokenKey, token);
  }

  /**
   * set access token
   * @param token - the access token to set in local storage
   */
  private setRefreshToken(token: string): void {
    this.localStorageService.setItem(this.accessToRefreshKey, token);
  }

  /**
   * To get the access token.
   * @returns
   */
  getAccessToken(): string {
    return this.localStorageService.getItem(this.accessTokenKey) ?? '';
  }

  /**
   * To get the refresh token.
   * @returns
   */
  getRefreshToken(): string {
    return this.localStorageService.getItem(this.accessToRefreshKey) ?? '';
  }

  /**
   * Check whether refresh token is valid or expired.
   * @returns boolean
   */
  isRefreshTokenExpired(): boolean {
    const decodedToken = jwtDecode<User>(this.getRefreshToken());
    if (!decodedToken) {
      return false;
    }
    return Number(decodedToken?.exp) < Math.floor(Date.now() / 1000);
  }

  /**
   * Check whether end user is logged in or not.
   * @returns
   */
  isLoggedIn(): boolean {
    return !!this.getAccessToken();
  }

  /**
   * Fetch User details
   * @returns
   */
  getUser(): User {
    const decodedToken = jwtDecode<User>(this.getAccessToken());
    return decodedToken;
  }

  /**
   * Save login credential to auto populate login
   * @param email - user email.
   * @param isRememberMeChecked - whether remember me is checked/unchecked.
   */
  saveCredentials(email: string, isRememberMeChecked: boolean): void {
    const credentials = { email, isRememberMeChecked };
    this.localStorageService.setItem(
      this.credentialStorageKey,
      JSON.stringify(credentials)
    );
  }

  /**
   * To get the login details
   * @returns
   */
  getCredentials(): { email: string; isRememberMeChecked: boolean } | null {
    const storedCredentials = this.localStorageService.getItem(
      this.credentialStorageKey
    );
    return storedCredentials ? JSON.parse(storedCredentials) : null;
  }

  /**
   * Logout the end user.
   */
  logout(): void {
    this.localStorageService.removeItem(this.accessTokenKey);
    this.localStorageService.removeItem(this.accessToRefreshKey);
    this.router.navigate(['/auth']);
  }

  /**
   * Change password
   * @param {number} id
   * @param {unknown} body
   * @return {*}  {Observable<User>}
   * @memberof AuthService
   */
  changePassword(body: unknown): Observable<User> {
    return this.http.post<User>(`${this.apiUrl}/auth/changePassword`, body);
  }

  /**
   * Logout the end user.
   */
  logoutUser(): void {
    const refreshToken = this.localStorageService.getItem(
      this.accessToRefreshKey
    );
    this.http
      .post<ApiResponse<Login>>(`${this.apiUrl}/auth/signOut`, {
        refreshToken,
      })
      .pipe(
        tap(() => {
          this.localStorageService.removeItem(this.accessTokenKey);
          this.localStorageService.removeItem(this.accessToRefreshKey);
          this.router.navigate(['/auth']);
        })
      )
      .subscribe();
  }
}
