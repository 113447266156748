import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import io, { Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket!: Socket;

  constructor() {
    this.socket = io(environment.apiUrl, { transports: ['websocket'] });
  }

  on<T>(event: string) {
    return new Observable<T>((observer) => {
      this.socket.on(event, (data) => {
        observer.next(data);
      });
    });
  }

  emit<T>(event: string, data: T) {
    this.socket.emit(event, data);
  }

  disconnect() {
    this.socket.disconnect();
  }

  connect() {
    this.socket.connect();
  }
}
