import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  /**
   * Get Item from localStorage
   * @param {string} key
   * @return {*} 
   * @memberof LocalStorageService
   */
  getItem(key: string) {
    const data = localStorage.getItem(key);
    const actualData = data ? JSON.parse(data) : '';
    return actualData;
  }

  /**
   * Set Item in localStorage
   * @param {string} key
   * @param {unknown} value
   * @memberof LocalStorageService
   */
  setItem(key: string, value: unknown) {
    const data = JSON.stringify(value);
    localStorage.setItem(key, data);
  }

  /**
   * Remove item from localStorage
   * @param {string} key
   * @memberof LocalStorageService
   */
  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  /**
   * Clear localStorage
   * @memberof LocalStorageService
   */
  clearStorage() {
    localStorage.clear();
  }
}
