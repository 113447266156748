import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { finalize } from 'rxjs';

import { LoaderService } from '../services/loader.service';

/**
 * Loader interceptor for show loader on every request
 * @param {*} req
 * @param {*} next
 * @return {*}
 */
export const loaderInterceptor: HttpInterceptorFn = (req, next) => {
  const loaderService = inject(LoaderService);

  const excludedUrls = ['/content/upload-movie', 'episode/generateHlsStream'];

  const isLoaderNeeded = !excludedUrls.some((url) => req.url.includes(url));

  if (isLoaderNeeded) {
    loaderService.show();
  }

  return next(req).pipe(
    finalize(() => {
      if (isLoaderNeeded) {
        setTimeout(() => {
          loaderService.hide();
        }, 500);
      }
    })
  );
};
