import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { delay, Observable } from 'rxjs';

import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-upload-progress-loader',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './upload-progress-loader.component.html',
  styleUrl: './upload-progress-loader.component.scss',
})
export class UploadProgressLoaderComponent {
  // subscriber for checking flag for show/hide progress
  isShowProgress: Observable<boolean> = this.loaderService.isShowProgress.pipe(
    delay(0)
  );

  uploadProgress: Observable<number> =
    this.loaderService.progressPercentage.pipe(delay(0));

  constructor(private loaderService: LoaderService) {}
}
