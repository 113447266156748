import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { SocketService } from './socket.service';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  // subscription to track loader status
  isLoading = new Subject<boolean>();

  // show progress for uploading content
  isShowProgress = new Subject<boolean>();

  //show progress percentage for uploading content
  progressPercentage = new Subject<number>();

  private uploadContentProgress!: Subscription;

  constructor(private socketService: SocketService) {}

  /**
   * Method for show loader.
   *
   */
  show(): void {
    this.isLoading.next(true);
  }

  /**
   * Method for hide loader.
   *
   */
  hide(): void {
    this.isLoading.next(false);
  }

  setSocketForUploadContent(socketUUID: string) {
    localStorage.setItem('socketUUID', socketUUID);
    this.uploadContentProgress = this.socketService.on(socketUUID).subscribe({
      next: (res) => {
        const progress = Number(res);
        this.progressPercentage.next(progress);
        if (progress < 100) {
          localStorage.setItem('uploadProgress', progress.toString());
        } else {
          this.hideProgress();
          this.destroyProgress();
        }
      },
      error: (err) => {
        console.error('Error in WebSocket subscription', err);
      },
    });
  }

  destroyProgress() {
    if (this.uploadContentProgress) {
      this.uploadContentProgress.unsubscribe();
    }
    localStorage.removeItem('socketUUID');
    localStorage.removeItem('uploadProgress');
  }

  /**
   * Method for show progress
   *
   * @memberof LoaderService
   */
  showProgress(): void {
    this.isShowProgress.next(true);
  }

  /**
   * Method for hide progress
   *
   * @memberof LoaderService
   */
  hideProgress(): void {
    this.isShowProgress.next(false);
  }
}
