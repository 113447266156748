import { AbstractControl } from '@angular/forms';

declare module '@angular/forms' {
  interface AbstractControl {
    showError(): boolean;
  }
}

/**
 * To display error for a control which has been invalid, dirty or touched.
 */
AbstractControl.prototype.showError = function () {
  return this.invalid && (this.dirty || this.touched);
};